@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.button-learn-more {
    padding: 17px 40px;
    border-radius: 50px;
    cursor: pointer;
    border: 0;
    background-color: white;
    box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    transition: all 0.5s ease;
  }
  
  .button-learn-more:hover {
    letter-spacing: 3px;
    background-color: hsl(211, 80%, 48%);
    color: hsl(0, 0%, 100%);
    box-shadow: rgb(24, 125, 220) 0px 7px 29px 0px;
  }
  
  .button-learn-more:active {
    letter-spacing: 3px;
    background-color: hsl(211, 80%, 48%);
    color: hsl(0, 0%, 100%);
    box-shadow: rgb(24, 142, 220) 0px 0px 0px 0px;
    transform: translateY(10px);
    transition: 100ms;
  }
  